<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Aplikasi
        </h5>
        <small class="text-muted">Arsitektur Domain Aplikasi SPBE untuk seluruh Aplikasi yang terkait dengan
          kegiatan.</small>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Domain Aplikasi"
          label-for="vi-app_domain_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Domain Aplikasi"
            rules="required"
          >
            <v-select
              id="vi-app_domain_id"
              v-model="app_domain_id"
              required
              name="app_domain_id"
              :options="referenceData.ref_domain_aplikasi"
              placeholder="Domain Aplikasi"
              label="nama"
              @input="getAreaAplikasi()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Area Aplikasi"
          label-for="vi-app_area_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Area Aplikasi"
            rules="required"
          >
            <v-select
              id="vi-service_area_id"
              v-model="app_area_id"
              required
              name="app_area_id"
              :options="daftarAreaAplikasi"
              placeholder="Area Aplikasi"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama aplikasi yang digunakan atau dimiliki.'"
          label="Nama Aplikasi"
          label-for="vi-nama_aplikasi"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Aplikasi"
            rules="required"
          >
            <b-form-input
              id="vi-nama_aplikasi"
              v-model="app_data.nama_aplikasi"
              required
              name="nama_aplikasi"
              placeholder="Nama Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan status kegiatan/pengadaan aplikasi.'"
          label="Status Kegiatan"
          label-for="vi-status_kegiatan_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Status kegiatan"
            rules="required"
          >
            <v-select
              id="vi-status_kegiatan_id"
              v-model="status_kegiatan_id"
              required
              name="status_kegiatan_id"
              :options="referenceData.ref_status_kegiatan"
              placeholder="Status Kegiatan"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(app_data.total_anggaran) ? ': Rp. ' + Number(app_data.total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran"
              v-model="app_data.total_anggaran"
              type="number"
              required
              name="total_anggaran"
              placeholder="Total Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Berisi keterangan fungsi dari aplikasi terhadap layanan yang didukung.'"
          label="Fungsi Aplikasi"
          label-for="vi-fungsi"
        >
          <validation-provider
            #default="{ errors }"
            name="Fungsi Aplikasi"
            rules="required"
          >
            <b-form-input
              id="vi-fungsi"
              v-model="app_data.fungsi"
              required
              name="fungsi"
              placeholder="Fungsi Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan hasil-hasil yang diperoleh dari aplikasi yang dimiliki atau digunakan.'"
          label="Luaran / Output"
          label-for="vi-output"
        >
          <validation-provider
            #default="{ errors }"
            name="Luaran / Output"
            rules="required"
          >
            <b-form-input
              id="vi-output"
              v-model="app_data.output"
              required
              name="output"
              placeholder="Luaran / Output"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Basis dari aplikasi (Desktop; Web; Cloud; atau Mobile).'"
          label="Basis Aplikasi"
          label-for="vi-basis_aplikasi_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Basis Aplikasi"
            rules="required"
          >
            <v-select
              id="vi-basis_aplikasi_id"
              v-model="app_data.basis_aplikasi_id"
              multiple
              required
              name="basis_aplikasi_id"
              :options="referenceData.ref_basis_aplikasi"
              placeholder="Basis Aplikasi"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Server yang digunakan oleh aplikasi, pilihan server yang digunakan didapat dari metadata perangkat keras server.'"
          label="Server Aplikasi"
          label-for="vi-server"
        >
          <validation-provider
            #default="{ errors }"
            name="Server Aplikasi"
            rules="required"
          >
            <b-form-input
              id="vi-server"
              v-model="app_data.server"
              required
              name="server"
              placeholder="Server Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Tipe lisensi dari aplikasi (Open Source/Proprietary)'"
          label="Tipe Lisensi Aplikasi"
          label-for="vi-jenis_lisensi_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Tipe Lisensi Aplikasi"
            rules="required"
          >
            <v-select
              id="vi-jenis_lisensi_id"
              v-model="app_data.jenis_lisensi_id"
              required
              name="jenis_lisensi_id"
              :options="referenceData.ref_jenis_lisensi"
              placeholder="Tipe Lisensi Aplikasi"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Bahasa pemrograman yang digunakan oleh aplikasi.'"
          label="Bahasa Pemrograman"
          label-for="vi-bahasa_pemrograman"
        >
          <validation-provider
            #default="{ errors }"
            name="Bahasa Pemrograman"
            rules="required"
          >
            <v-select
              id="vi-bahasa_pemrograman"
              v-model="app_data.bahasa_pemrograman"
              multiple
              required
              name="bahasa_pemrograman"
              placeholder="Bahasa Pemrograman"
              :options="referenceData.ref_bahasa_pemrograman"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Kerangka atau Framework yang digunakan oleh aplikasi.'"
          label="Kerangka/Framework Pengembangan"
          label-for="vi-aplikasi_framework"
        >
          <validation-provider
            #default="{ errors }"
            name="Kerangka/Framework Pengembangan"
            rules="required"
          >
            <v-select
              id="vi-aplikasi_framework"
              v-model="app_data.aplikasi_framework"
              multiple
              required
              name="aplikasi_framework"
              placeholder="Kerangka/Framework Pengembangan"
              :options="referenceData.ref_framework"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Basis data yang digunakan oleh aplikasi.'"
          label="Basis Data"
          label-for="vi-basisdata"
        >
          <validation-provider
            #default="{ errors }"
            name="Basis Data"
            rules="required"
          >
            <v-select
              id="vi-basisdata"
              v-model="app_data.basisdata"
              multiple
              required
              name="basisdata"
              placeholder="Basis Data"
              :options="referenceData.ref_database"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Unit yang melakukan pembangunan dan pengembangan aplikasi.'"
          label="Unit Pengembang"
          label-for="vi-unit_pengembang"
        >
          <validation-provider
            #default="{ errors }"
            name="Unit Pengembang"
            rules="required"
          >
            <b-form-input
              id="vi-unit_pengembang"
              v-model="app_data.unit_pengembang"
              required
              name="unit_pengembang"
              placeholder="Unit Pengembang"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Unit yang melakukan operasional teknologi layanan.'"
          label="Unit Operasional Teknologi"
          label-for="vi-unit_operasional_teknologi"
        >
          <validation-provider
            #default="{ errors }"
            name="Unit Operasional Teknologi"
            rules="required"
          >
            <b-form-input
              id="vi-unit_operasional_teknologi"
              v-model="app_data.unit_operasional_teknologi"
              required
              name="unit_operasional_teknologi"
              placeholder="Unit Operasional Teknologi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Berisi uraian atau deskripsi secara umum dari aplikasi.'"
          label="Uraian Aplikasi"
          label-for="vi-uraian_aplikasi"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Aplikasi"
            rules="required"
          >
            <b-form-textarea
              id="vi-uraian_aplikasi"
              v-model="app_data.uraian_aplikasi"
              rows="8"
              required
              name="uraian_aplikasi"
              placeholder="Uraian Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="app_data.kak_uri !== null"
        md="6"
        class="mb-50"
      >
        <h5>Kerangka Acuan Kerja</h5>
        <p>{{ app_data.kak_uri.substring(app_data.kak_uri.lastIndexOf('/') + 1) }}
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(app_data.kak_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(app_data.kak_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
        </p>
      </b-col>
      <b-col
        v-if="app_data.additional_docs.length !== 0"
        md="6"
        class="mb-50"
      >
        <h5>Dokumen Tambahan</h5>
        <p
          v-for="(data, index) in app_data.additional_docs"
          :id="`dt-${data.document_id}`"
          :key="index"
        >
          {{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(data.document_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(data.document_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <b-button
            variant="outline-danger"
            size="sm"
            class="mr-25 mb-25"
            @click="removeFile(data.document_id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="14"
            /></b-button>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf)'"
          label="Kerangka Acuan Kerja (dalam format PDF)"
          label-for="file_kak"
        >
          <validation-provider
            #default="{ errors }"
            name="File KAK"
            rules="required"
          >
            <b-form-file
              ref="file_kak"
              v-model="file1"
              name="file_kak"
              class="mt-1"
              required
              @input="checkFile"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Upload file Tambahan (dalam format .pdf)'"
          label="Dokumen Tambahan (dalam format PDF)"
          label-for="file_extra_doc"
        >
          <b-form-file
            ref="file_extra_doc"
            v-model="file2"
            name="file_extra_doc"
            class="mt-1"
            multiple
            @input="checkFile2"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Dokumen Tambahan?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BButton, BFormFile, BFormInput, BRow, BCol, BFormGroup,
  BFormTextarea, VBTooltip, BLink, BModal, BCardText,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import VuePdfApp from 'vue-pdf-app'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BLink,
    BButton,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BCardText,
    ValidationProvider,
    vSelect,
    VuePdfApp,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => { },
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        ref_domain_aplikasi: [],
        ref_area_aplikasi: [],
        ref_status_kegiatan: [],
        ref_area_layanan: [],
        ref_data_tematik: [],
        ref_bahasa_pemrograman: [],
        ref_basis_aplikasi: [],
        ref_database: [],
        ref_framework: [],
        ref_jenis_lisensi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      data_utama_id: '',
      errorMsg: '',
      aplikasi_id: '',
      dataSaved: false,
      app_data: {
        basis_aplikasi_id: [],
        bahasa_pemrograman: [],
        aplikasi_framework: [],
        jenis_lisensi_id: [],
        basisdata: [],
        kak_uri: null,
        additional_docs: [],
      },
      app_domain_id: '',
      app_area_id: '',
      status_kegiatan_id: '',
      basis_aplikasi_id: [],
      bahasa_pemrograman: [],
      aplikasi_framework: [],
      basisdata: [],
      file1: null,
      file2: [],
      daftarAreaAplikasi: [],
      fileDoc: '',
      toDelData: '',
    }
  },
  watch: {
    actionStep() {
      this.simpanAplikasi()
    },
    clearanceId() {
      this.getReference()
    },
  },
  mounted() {
    this.getReference()
    // this.aplikasi_id = 837
  },
  methods: {
    checkFile() {
      if (this.file1) {
        if (this.file1.size > 100 * 1024 * 1024) {
          this.errorMsg = `${this.file1.name}: Ukuran Dokumen Terlalu Besar`
        } if (this.file1.type !== 'application/pdf') {
          this.errorMsg = `${this.file1.name}: Jenis Dokumen Tidak Sesuai`
        } else {
          this.errorMsg = ''
        }
      }
    },
    checkFile2() {
      if (this.file2.length !== 0) {
        const notif = []
        let not = false
        for (let i = 0; i < this.file2.length; i += 1) {
          const alert = []
          if (this.file2[i].size > 100 * 1024 * 1024) {
            not = true
            alert.push(`${this.file2[i].name}: Ukuran Dokumen Terlalu Besar`)
          } if (this.file2[i].type !== 'application/pdf') {
            not = true
            alert.push(`${this.file2[i].name}: Jenis Dokumen Tidak Sesuai`)
          }
          if (not) {
            notif.push(alert)
          }
        }
        this.errorMsg = (notif.length !== 0) ? notif : ''
      }
    },
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    removeFile(value) {
      this.toDelData = value
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
    },
    deleteItem() {
      this.$http.post('/clearance/applications/file/delete', {
        document_id: this.toDelData,
        aplikasi_id: this.aplikasi_id,
      }, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById(`dt-${this.toDelData}`).remove()
            this.clearDeleteItem()
          }
        })
    },
    getReference() {
      if (this.clearanceId) {
        this.$http.get('/clearance/applications/references', {
          params: {
            token: localStorage.getItem('userToken'),
            data_utama_id: this.clearanceId,
            tahun_anggaran: this.coreData.tahun_anggaran,
          },
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.referenceData = res.data.data

              const options = this.referenceData.default_options
              this.referenceData.ref_area_layanan.map(contact => {
                if (contact.area_layanan_id === options.ref_area_layanan) {
                  this.area_layanan_id = contact
                }
                return true
              })
            }
          })
      }
      return true
    },
    getAreaAplikasi() {
      this.daftarAreaAplikasi.splice(0)
      this.app_area_id = ''

      const filterVal = this.app_domain_id.domain_aplikasi_id
      const refData = this.referenceData.ref_area_aplikasi
      refData.map(contact => {
        if (contact.domain_aplikasi_id === filterVal) {
          this.daftarAreaAplikasi.push(contact)
        }
        return true
      })
    },
    simpanAplikasi() {
      if (!this.kak_uri && !this.file1) {
        const data = {
          status: false,
          msg: 'File KAK Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
      } else {
        this.saveAplikasi()
      }
    },
    saveAplikasi() {
      this.errorMsg = ''
      document.getElementById('loading-bg').style.display = 'block'
      this.basis_aplikasi_id = []
      this.bahasa_pemrograman = []
      this.aplikasi_framework = []
      this.basisdata = []
      this.jenis_lisensi_id = []

      this.app_data.basis_aplikasi_id.map(value => this.basis_aplikasi_id.push(value.basis_aplikasi_id))
      this.app_data.bahasa_pemrograman.map(value => this.bahasa_pemrograman.push(value.bahasa_pemrograman_id))
      this.app_data.aplikasi_framework.map(value => this.aplikasi_framework.push(value.aplikasi_framework_id))
      this.app_data.basisdata.map(value => this.basisdata.push(value.basisdata_id))

      const metaUtama = {
        data_utama_id: this.clearanceId,
        instansi_id: this.userData.instansi_id,
        domain_aplikasi_id: this.app_domain_id.domain_aplikasi_id,
        area_aplikasi_id: this.app_area_id.area_aplikasi_id,
        nama_aplikasi: this.app_data.nama_aplikasi,
        status_kegiatan_id: this.status_kegiatan_id.status_kegiatan_id,
        total_anggaran: Number(this.app_data.total_anggaran),
        fungsi: this.app_data.fungsi,
        output: this.app_data.output,
        basis_aplikasi: this.basis_aplikasi_id,
        server: this.app_data.server,
        jenis_lisensi_id: this.app_data.jenis_lisensi_id.jenis_lisensi_id,
        bahasa_pemrograman: this.bahasa_pemrograman,
        aplikasi_framework: this.aplikasi_framework,
        basisdata: this.basisdata,
        unit_pengembang: this.app_data.unit_pengembang,
        unit_operasional_teknologi: this.app_data.unit_operasional_teknologi,
        uraian_aplikasi: this.app_data.uraian_aplikasi,
      }
      if (this.aplikasi_id !== '') {
        metaUtama.aplikasi_id = this.aplikasi_id
      }
      this.$http.post('/clearance/applications', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.clearanceId,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            const status = true
            this.aplikasi_id = res.data.data.aplikasi_id

            const promise1 = new Promise(resolve => {
              if (this.file1) {
                const formFile = new FormData()
                formFile.append('clearance_id', this.coreData.clearance_id)
                formFile.append('data_utama_id', this.clearanceId)
                formFile.append('aplikasi_id', this.aplikasi_id)
                formFile.append('kak_file', this.file1)

                this.$http.post('/clearance/applications/file/upload', formFile, {
                  params: {
                    token: localStorage.getItem('userToken'),
                  },
                })
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      this.app_data.kak_uri = resp.data.data[0].document_uri
                      resolve(true)
                    } else {
                      const data = {
                        status: false,
                        msg: resp.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = resp.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    const data = {
                      status: false,
                      msg: error.response.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.errorMsg = error.response.data.error
                    this.$emit('step-completed', data)
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            const promise2 = new Promise(resolve => {
              if (this.file2.length !== 0) {
                for (let i = 0; i < this.file2.length; i += 1) {
                  const formFile = new FormData()
                  formFile.append('clearance_id', this.coreData.clearance_id)
                  formFile.append('data_utama_id', this.clearanceId)
                  formFile.append('aplikasi_id', this.aplikasi_id)
                  formFile.append('additional_files', this.file2[i])

                  this.$http.post('/clearance/applications/file/upload', formFile, {
                    params: {
                      token: localStorage.getItem('userToken'),
                    },
                  })
                    .then(resp => {
                      if (resp.data.status === 'success') {
                        this.app_data.additional_docs.push(resp.data.data[0])
                        resolve(true)
                      } else {
                        const data = {
                          status: false,
                          msg: resp.data.error,
                        }
                        document.getElementById('loading-bg').style.display = 'none'
                        this.errorMsg = resp.data.error
                        this.$emit('step-completed', data)
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      const data = {
                        status: false,
                        msg: error.response.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = error.response.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise1, promise2, status]).then(values => {
              if (values[0] && values[1] && values[2]) {
                const data = {
                  status: true,
                  msg: this.app_data,
                }
                document.getElementById('loading-bg').style.display = 'none'
                data.msg.data_utama_id = this.clearanceId
                this.$refs.file_extra_doc.reset()
                this.file2 = []
                this.dataSaved = true
                this.errorMsg = ''
                this.$emit('step-completed', data)
              }
            })
          } else {
            document.getElementById('loading-bg').style.display = 'none'
            this.errorMsg = `${res.data.message}: ${res.data.error}`
          }
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          document.getElementById('loading-bg').style.display = 'none'
          this.errorMsg = error.response.data.error
          this.$emit('step-completed', data)
        })
    },
  },
}
</script>
